import React from 'react'

import { Row, Col, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Select, Button, Input } from '../../Components/UI'

export const FiltrosTorneo = ({
  enableButton,
  filters,
  data,
  events,
  states,
  isMobile
}) => {
  return (
    <Jumbotron>
      <Row>
        <Col md={enableButton ? 8 : 12}>
          <h1>Torneos</h1>
          <p>Puedes aplicar filtros para acotar tu búsqueda de Torneos.</p>
        </Col>
        {enableButton && (
          <Col md={4} xs={12} className='text-center'>
            <Link
              to='/tournament/register'
              className='btn btn-success'
              style={{
                margin: 'auto',
                marginBottom: '20px',
                display: 'block'
              }}>
              Crear Torneo
            </Link>
          </Col>
        )}
      </Row>
      <div className='Tournaments__filter row'>
        <Col md={4} className='text-center'>
          <form onSubmit={events.handleSubmit} className=''>
            <Input
              inputSize={12}
              name='search'
              placeholder='Buscar por nombre del torneo'
              value={filters.search}
              onChange={events.onChange}
            />
          </form>
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='gameId'
            options={states.games}
            placeholder={'Selecciona un Juego'}
            onChange={events.onChange}
            select={filters.gameId}
          />
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='isActiveSeason'
            options={states.seaonsActives}
            placeholder={'Selecciona temporadas'}
            onChange={events.onChange}
            select={filters.isActiveSeason}
          />
        </Col>
        {states.showFilters && (
          <React.Fragment>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='seasonId'
                options={data.Seasons}
                placeholder={'Temporadas de juegos'}
                onChange={events.onChange}
                select={states.seasonId}
              />
            </Col>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='countryId'
                options={data.Countries}
                placeholder={'Todos los Paises'}
                onChange={events.onChange}
                select={filters.countryId}
              />
            </Col>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='regionId'
                options={data.Regions}
                placeholder={'Todos las regiones'}
                onChange={events.onChange}
                select={filters.regionId}
              />
            </Col>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='typeId'
                options={data.Types}
                placeholder='Todos los Tipos'
                onChange={events.onChange}
                select={filters.typeId}
              />
            </Col>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='statusId'
                options={states.status}
                placeholder='Todos los estados de Torneos'
                onChange={events.onChange}
                select={filters.statusId}
              />
            </Col>
            <Col md={4} className='text-center'>
              <Select
                inputSize={12}
                name='isPresential'
                options={states.presentials}
                placeholder=''
                onChange={events.onChange}
                select={filters.isPresential}
              />
            </Col>
            <Col md={12} className='text-center'>
              <div className='buttons flex'>
                <Button
                  text='Limpiar filtros'
                  onClick={events.resetFilters}
                  state='default'
                />
              </div>
            </Col>
          </React.Fragment>
        )}
        {isMobile && (
          <Button
            text={!states.showFilters ? 'Mostrar Filtros' : 'Ocultar Filtros'}
            state='info'
            style={{
              margin: 'auto',
              marginTop: states.showFilters ? '20px' : ''
            }}
            onClick={() => {
              this.setState({
                showFilters: !states.showFilters
              })
            }}
          />
        )}
      </div>
    </Jumbotron>
  )
}
