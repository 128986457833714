import styled from 'styled-components'
import { bgBackground } from '../../../assets/img/login'

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournaments' })`
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  article {
    h1 {
      text-align: left;
    }
  }
  .Tournaments__sort {
    text-align: right;
    span {
      margin-left: 10px;
    }
  }
`

export default Wrapper
