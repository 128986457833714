const BaseLeft = require('./Kilimanjaro/')

// @Dependencies
const logoMyl = require('./logo_myl.png')
const logoHK = require('./logo_hk.png')
const logoMyLHK = require('./logo_myl-hk.png')
const CthulhuElGrande = require('./cthulhu_el_grande.jpg')
const ZombieArturo = require('./Zombie_Arturo.jpeg')
const ZombieValkiria = require('./Zombie_Valkiria.jpeg')
const ZombieZeus = require('./Zombie_Zeus.jpeg')
const Obatala = require('./Kilimanjaro/obatala-min.png')

const ZeusWalkirias = require('./Zeus-Walkirias.jpg')
const Fondo01 = require('../Fondo01.png')
const Fondo02 = require('../Fondo02.jpg')
const Fondo03 = require('../Fondo03.png')
const Fondo04 = require('../Fondo04.png')

/**
 *
 * @param {'zeus-walki'|'default'|'fondo1'} param
 * @returns
 */
const bgBackground = (param = '') => {
  const mapLogos = new Map([
    ['default', ZombieArturo],
    ['zeus-walki', ZeusWalkirias],
    ['fondo1', Fondo01],
    ['fondo2', Fondo02],
    ['fondo3', Fondo03],
    ['fondo4', Fondo04],

    ['zeus', ZombieZeus],
    ['cthulhu', CthulhuElGrande],
    ['walkirias', ZombieValkiria],
    ['arthur', ZombieArturo],
    ['obatala', Obatala]
  ])

  if (!param.length) {
    const path = window.location.pathname.split('/')
    if (path.length > 1) {
      if (mapLogos.has(path[1])) {
        return mapLogos.get(path[1])
      }
    }
  }

  return mapLogos.has(param) ? mapLogos.get(param) : mapLogos.get('default')
}

/**
 * Export module
 */
module.exports = {
  logoMyl,
  logoHK,
  logoMyLHK,
  BaseLeft,
  bgBackground
}
