import React from 'react'
import Wrapper from './Styles'
import { Container } from 'reactstrap'

import { FiltrosTorneo } from './Filtros'
import { TODOS } from '../Tournaments/constants'
export default function () {
  const events = {
    handleSubmit: e => {
      // console.log(e)
    },
    onChange: e => {
      // console.log(e)
    },
    resetFilters: e => {
      // console.log(e)
    },
    search: e => {
      // console.log(e)
    }
  }
  const filters = {
    page: 1,
    limit: 21,
    formatId: 0,
    typeId: 0,
    storeId: 0,
    countryId: 0,
    gameId: 0,
    isActiveSeason: 1,
    regionId: 0,
    isPresential: 0,
    search: '',
    date: 'DESC',
    players: 'DESC',
    /** Flag para cursos futuros solamente. */
    statusId: TODOS
  }
  const states = {
    seasons: [],
    presentials: [
      {
        id: 0,
        name: 'Todos los torneos'
      },
      {
        id: 2,
        name: '📍 Torneos Presenciales'
      },
      {
        id: 1,
        name: '🖥️ Torneos Remotos'
      }
    ],
    seaonsActives: [
      {
        id: 0,
        name: '⚠️ Temporadas pasadas'
      },
      {
        id: 1,
        name: '✅ Temporadas actuales'
      },
      {
        id: 2,
        name: 'Temporadas pasadas y actuales (todas)'
      }
    ],
    games: [],
    status: [],
    seasonId: 0
  }
  return (
    <Wrapper>
      <Container>
        <FiltrosTorneo
          enableButton={true}
          events={events}
          filters={filters}
          states={states}
        />
      </Container>
    </Wrapper>
  )
}
